import { navigate } from 'gatsby'
import React from 'react'

import paths from 'constants/paths'

export default function Home() {
  React.useEffect(() => {
    navigate(paths.DASHBOARD)
  })

  return null
}
